/* Centering the loader container */
body {
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0; /* Optional: add a background color */
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader-svg {
  width: 4em; /* Reduced size */
  height: 4em; /* Maintain aspect ratio */
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

.loader-circle {
  fill: none;
  stroke: hsl(214, 97%, 59%);
  stroke-width: 4; /* Adjusted thickness */
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

.loading-text {
  margin-top: 1em; /* Space between loader and text */
  font-size: 1.2em; /* Adjust font size */
  color: hsl(214, 97%, 39%); /* Darker shade for contrast */
  font-family: Arial, sans-serif; /* Change font family */
  font-weight: bold; /* Make text bold */
  text-align: center; /* Center the text */
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -125px;
  }
}
